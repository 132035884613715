.itemTitle {
    font-family: 'Montserrat';
    font-size: 32px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 15px;
    overflow-wrap: break-word;
    color: black;
}

.itemImgBlock {
    position: relative;
    min-height: 235px;
    .imgList {
        position: absolute;
        /*overflow-y: scroll;*/
        height: 300px;
        overflow: auto;
        @media (max-width: 570px) {height: 200px; margin-left: 10px;}
        img {
            width: 60px;
            height: 60px;
            padding: 4px;
            object-fit: scale-down;
            border: 1px solid #b3b3b7;
            border-radius: 5px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .itemImg {
        text-align: center;
        padding: 15px;
        img {
            cursor: pointer;
            height: 300px;
            max-width: 600px;
        }
        @media (max-width: 570px) {
            img {height: 180px;     max-width: 345px;}
        }
    }
}





.cardData {
    display:flex;
    .cardTitle {
        flex: 0 0 200px;
    }
    .cardText {
        flex: 1 1 auto;
        color: grey;
        div {
            height: 21px;
        }
    }
}



.fancybox__backdrop {
    opacity: 0.8 !important;
}

.noAvailableCatPage {
    color: #b3b3b7;
    font-size: 36px;
    font-weight: 700;
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
}
