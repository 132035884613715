@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@400;800&display=swap');

.main {
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    overflow-x: clip;
}

.mainHeader {
    position: fixed;
    width: 100%;
    z-index: 3;
    background-color: #fff;
    box-shadow: 0 1px 10px -6px rgb(0 0 0 / 42%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 4px 5px -2px rgb(0 0 0 / 10%);
    .navHeader {
        display: flex;
        .navHeader-img{
            flex: 0 0 58px;
            margin-top: 6px;
            img{width: 58px;}
        }
        .navHeader-menu{
            flex: 1 1 auto;
            ul {
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 40px;
                margin: 4px auto;
                li {
                    white-space: nowrap;
                    margin: 0 10px;
                    position: relative;
                    a {
                        color: #000;
                        font-size: 12px;
                        font-weight: 600;
                        text-transform: uppercase;
                        &:hover, &.active {
                            text-decoration: none;
                            cursor: pointer;
                            /*color: #260852;*/
                            color: #3f51b5;
                            transition: color ease-in-out .2s;
                            &:after {
                                content: '';
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: -14px;
                                height: 2px;
                                background: #3f51b5;
                            }
                        }
                    }
                }
            }
            .navHeaderMiniMenu {
                display: none;
                padding-top: 5px;
                justify-content: end;
                button { color: black}
            }
            @media (max-width:1020px ) {
                ul {display: none}
                .navHeaderMiniMenu {display: flex}
            }
            .navHeaderMiniMenu2{a{color: #3f51b5;}}
        }
        .navHeader-title{
            padding-top: 6px;
            flex: 1 1 auto;
            font-family: 'Alegreya', serif;
            letter-spacing: 2px;
            color: #260852;
            font-size: 24px;
            font-weight: 600;
            text-transform: uppercase;
            font-style: italic;
            white-space: nowrap;
            padding-left: 10px;
        }
        @media (max-width:560px ) {
            .navHeader-title {
                font-size: 21px;
                padding-top: 10px;
            }
        }
    }
}
.smallMenuItem {
    color: #000 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: uppercase;
    border-bottom: 1px solid #dedede !important;
    &:hover {
        color: #3f51b5 !important;
        background: #cecece4f;
    }
}

.footer {
    margin-top: 100px;
    background: #f3f3f3;
    padding: 8px 0px;
    display: inline-flex;
    flex-wrap: wrap;
    position: relative;
    .footerline {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        background: #f3f3f3;
        width: 105%;
        height: 100px;
        position: absolute;
        top: -50px;
        left: -2%;
        transform: rotate(3deg);
    }
    .footerLeft{
        z-index: 100;
        flex: 1 1 60%;
        font-style: italic;
        font-size: 12px;
        align-items: baseline;
    }
    .footerRight {
        flex: 1 1 250px;
        z-index: 100;
        p {
            padding-left: 100px;
            font-size: 16px;
        }
        .footerTitle {
            font-family: "Alegreya", serif;
            letter-spacing: 2px;
            font-size: 24px;
            font-weight: 600;
            text-transform: uppercase;
            font-style: italic;
            white-space: nowrap;
            text-align: center;
            text-shadow: 1px 1px 1px #fff;
        }
    }

}

.pageTitle {
    padding: 15px 35px;
    span {
        font-weight: 600;
        font-size: 20px;
    }
}
.pageBody {
    padding: 0 35px;
    .noDataWithFilters {
        text-align: center;
        font-size: 16px;
        font-style: italic;
        color: #1976d2;
        font-weight: 600;
    }
}
.catalogTopFilterBlock {
    padding-left: 30px;
}

.botLogo {
    width: 250px;
    position: absolute;
    top: -30px;
    left: 30px;
}
@media (max-width: 820px) {.botLogo{width: 200px;}}
@media (max-width: 600px) {.botLogo{opacity: .3;}}

a {text-decoration: none;}

.iconAlign {
    padding-right: 5px;
    font-size: 25px !important;
    color: #260852;
}

.blockTitle {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
}
.blocks {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    margin: 20px 4px;
}

.scrollTop {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px !important;
    right: 11px !important;
    bottom: 65px !important;
    border: 1px solid #1976d2 !important;
    color: #1976d2;
}

