.modal {
    position: absolute;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    .modalContent {
        margin: 15% auto;
        width: 60%;
        background-color: #e8e9ec !important;
        .modalBody {
            padding: 15px;
            .mb {
                margin-bottom: 10px;
            }
            input, div>textarea {
                background-color: #fff;
            }
        }
    }
    @media (max-width: 860px) {.modalContent {width: 70%}}
    @media (max-width: 660px) {.modalContent {width: 90%}}
    .close {
        display: flex;
        font-weight: 600;
        padding: 5px;
        >div {
            margin: 9px auto;
            font-size: 16px;
        }
    }
}