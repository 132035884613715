$blockShadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

.titleBlock {
    text-align: center;
    font-weight: 600;
    font-size: 27px;
    display: flex;
    justify-content: center;
    font-family: "Alegreya", serif;
    letter-spacing: 2px;
    color: #260852;
    text-transform: uppercase;
    font-style: italic;
    white-space: nowrap;
    margin-top: 35px;
    margin-bottom: 10px;
    z-index: 100;
    .blockBody {
        /*box-shadow: $blockShadow;
        background-color: #ffffff;
        width: 90%;*/
        margin: -10px 0 10px 0;
        padding: 10px;
    }
}

.blockInfo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px;
    padding: 10px;
    min-width: 300px;
    height: 90px;
    /*box-shadow: $blockShadow;*/
    background-color: #ffffffed;
    transition: 0.15s all ease-in-out;
    box-shadow: 0 2px 4px 0 rgba(136, 144, 195, 0.2), 0 5px 15px 0 rgba(37, 44, 97, 0.15);
    border-radius: 3px;
    transform: rotate(2deg);
    cursor: pointer;
    font-size: 16px;
    > div:nth-child(2) {
        font-weight: 600;
        color: #676767;
    }
    > div:nth-child(3) {
        font-style: italic;
    }
    &:hover {
        background-color: rgba(255, 255, 255, 0.9215686275);
        transform: scale(1.1);
    }

}

.catalogCard {
    margin: 6px;
    width: 300px;
    .catalog-cards-column {
        flex: 0 1 300px;
        transition: transform 0.1s ease-in-out;
        box-shadow: 0 2px 4px 0 rgb(136 144 195 / 20%), 0 5px 15px 0 rgb(37 44 97 / 15%);
        border-radius: 15px;
        &:hover {
            transform: translateY(-0.8rem) scale(1.0125);
            background-color: #fff;
        }
        .notAvailable {
            background: grey;
            padding: 5px;
            text-align: right;
            color: #fff;
            position: absolute;
            right: 0;
            top: 76px;
            border-radius: 4px 0 0 4px;
            z-index: 10;
        }
        .cardTitle {
            font-size: 16px;
            padding: 10px 10px 0px 10px;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 50px;
        }
        .cardSubTitle {
            color: black;
            &.brand {
                padding-left: 23px;
            }
            &.model {
                padding-left: 13px;
            }
        }
    }
}

.checkbox {
    margin: 5px 0px;
    cursor: pointer;
    position: relative;
    input {
        width: 27px;
        height: 17px;
        position: absolute;
        cursor: pointer;
    }
    label {
        margin: 0;
        font-size: 1rem;
        line-height: 1.5;
        padding-left: 40px;
        cursor: pointer;
    }
}

.navBreadcrumb {
    padding-left: 35px;
    .brIcon {
        vertical-align: middle;
    }
    .brTitle {
        color: #000;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }
    .brLink {
        color: #1976d7;
    }
}

.brandLogo {
    position: absolute;
    right: 15px;
    bottom: -10px;
    img {
        width: 150px;
    }
}

.itemPrice {
    position: absolute;
    right: 15px;
    top: -22px;
    font-size: 24px;
    color: #242424;
    font-style: italic;
    .price{
        color: indianred;
    }
}