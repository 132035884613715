.lowerHeader {
    padding: 20px 25px 0px;
    margin-top: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #f3f3f3;
    height: 270px;
    position: relative;
}
.slick-slide img {
    width: 350px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
@media (max-width: 650px) {.lowerHeader {height: 240px;}}

@media (max-width: 560px) {
    .lowerHeader {height: 205px;}
    .slick-slide img {width: 300px;}
}

@media (max-width: 400px) {
    .lowerHeader {height: 170px;}
    .slick-slide img {width: 250px;}
}


.slick-initialized .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.globalSearch {
    position: absolute;
    width: 300px;
    height: 20px;
    right: 20px;
    bottom: -16px;
    padding: 8px 0;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 10px -6px rgba(0, 0, 0, 0.42), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px -2px rgba(0, 0, 0, 0.1);
    .MuiTextField-root {
        top: 4px;
        right: 0;
        width: 94%;
        padding: 0 10px;
    }
}