$gritTPL: 30% 50% 20%;

.tablePrice p {
    text-align: right;
}

.list {
    display: grid;
    grid-template-columns: $gritTPL;
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    >div:last-child {
        text-align: right;
    }
    >div:first-child {
        font-weight: 600;
    }
    &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
    }
}

.listTitle {
    display: grid;
    grid-template-columns: $gritTPL;
    text-align: center;
    font-weight: 600;
    padding: 10px;
    /*border: 1px solid rgba(0, 0, 0, 0.12);*/
    background-color: #f3f3f3;
    /*>div:not(:first-child) {
        border-left: 1px solid grey;
    }*/
}

