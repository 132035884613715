.work {
    .icon {
        padding: 0 5px;
        color: #787878;
    }
    .day, .time {
        padding: 10px 5px;
    }
    .day {
        flex: 0 1 50%;
    }
}
.active1 {
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0.08) !important;
}

.holiday {
    color: red !important;
    font-weight: 600;
    .icon {
        color: red !important;
    }
}

.map{margin: 0 auto;}




.yaMap {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.contactBlock {
    display: flex;
    flex-wrap: wrap;
    .block1 {
        flex: 1 1 40%;
    }
    .block2 {
        flex: 1 1 40%;
        min-width: 280px;
    }
    @media (max-width: 560px) {.block1{flex-basis:100%;}}
}
.subTitle {
    font-weight: 600;
    font-size: 20px;
    font-style: italic;
    margin-left: 20px;
    margin-bottom: 15px;
    text-align: center;
}

.scheduleBlock {
    width: 70%;
    margin: 0 auto;
}
@media (max-width: 560px) {.scheduleBlock{min-width: 360px}}
.notice {
    font-style: italic;
    text-align: center;
    margin-top: 10px;
}

