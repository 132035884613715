.breadcrump {
    padding-left: 30px;
    text-align: center;
}

.catalogWrapper {
    margin-top: 30px;
    display:flex;
    .catalogWrapper-filters{flex: 0 0 250px; margin-bottom: 15px;}
    .catalog-cards {
        flex: 1 1 auto;
        .catalog-cards-row {
            display: flex;
            flex-wrap: wrap;
            padding-left: 20px;
            margin-top: 5px;
        }
    }
}
@media (max-width: 640px){
    .catalogWrapper{
        flex-wrap: wrap;
        .catalogWrapper-filters {flex: 0 0 100%;}
        .catalog-cards {
            .catalog-cards-row {padding-left: 0;}
        }
    }
    .pageBody {padding: 0}
}

.price {
    text-align: right;
    font-weight: 600;
    color: black;
}


.filtersContainer {
    padding: 30px 10px 10px 10px;
    position: relative;
    /*.filterName {
        margin-top: 15px;
        margin-bottom: 10px;
        border-radius: 3px;
        background-color: #fff;
    }*/
    .filterName2 {
        font-size: 15px;
        font-weight: 600;
        background: #f2f2f1;
        box-shadow: 0 1px 0 0 #d7d8db;
        padding: 1px 5px;
        position: absolute;
        left: -10px;
        width: 104%;
    }
    .filterHeader {
        font-size: 16px;
        font-weight: 600;
        position: absolute;
        top: -14px;
        left: -4px;
        text-align: center;
        background: #f2f2f1;
        border-radius: 10px;
        line-height: 36px;
        padding: 0 10px;
        box-shadow: 0 1px 0 0 #d7d8db;
        width: 95%;
    }
    .filterCheckBox {
        /*border-top: 1px solid #b7b7b7;*/
        max-height: 350px;
        display: grid;
        overflow-y: auto;
        overflow-x: hidden;
;
    }
}


.catalog-row {
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    .catalog-column {
        flex: 0 1 30%;
        padding: 15px;
        min-width: 350px;
        .catalog-container {
            transition: .15s all ease-in-out;
            box-shadow: 0 2px 4px 0 rgb(136 144 195 / 20%), 0 5px 15px 0 rgb(37 44 97 / 15%);
            border-radius: 15px;
            position: relative;
            max-width: 500px;
            transform: rotate(2deg);
            &:hover {
                background-color: #ffffffeb;
                transform: scale(1.1);
            }
            .catalog-text {
                line-height: 2;
                font-weight: 600;
                font-size: 20px;
                font-family: "Alegreya", serif;
                letter-spacing: 2px;
                color: #260852;
                white-space: nowrap;
                position: absolute;
                z-index: 2;
                /*background-color: rgba(255, 255, 255, 0.7);*/
                width: 80%;
                top: 15px;
                left: 15px;
            }
            .catalog-item {
                height: 250px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
            }
        }
    }
}



